/* eslint-disable @typescript-eslint/no-unused-vars */
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { IError } from 'wd-platform-library'
import { ErrorInterFace } from 'wd-platform-library/lib/src/IError/src/IErrorInterFace'
export default defineComponent({
    name: 'error',
    components: {
        IError
    },
    setup () {
        const route = useRoute()
        const router = useRouter()
        const errorData = ref<ErrorInterFace>({
            code: '400',
            isRefresh: false,
            text: '',
            imgData: require('@/assets/img/error/no_404.png')
        })
        const countdownBackTime = ref(6)
        errorData.value.code = route.params.code as string
        switch (errorData.value.code) {
        case '400':
            errorData.value.imgData = require('@/assets/img/error/no_404.png')
            break
        case '401':
            errorData.value.imgData = require('@/assets/img/error/no_auth.png')
            errorData.value.text = '抱歉，未经授权只能止步于此...'
            break
        case '402':
            errorData.value.imgData = require('@/assets/img/error/no_404.png')
            break
        case '404':
            errorData.value.imgData = require('@/assets/img/error/no_404.png')
            errorData.value.text = '网络走失未归，点击重新加载试试找回它吧~'
            break
        case '500':
            errorData.value.imgData = require('@/assets/img/error/no_network.png')
            errorData.value.text = '系统异常，攻城狮正在抓紧时间抢修...'
            break
        }
        const countdownBack = () => {
            countdownBackTime.value = countdownBackTime.value - 1 >= 0 ? countdownBackTime.value - 1 : 0
            if (countdownBackTime.value === 0) {
                window.location.href = '/'
            } else {
                setTimeout(() => {
                    countdownBack()
                }, 1000)
            }
        }
        onMounted(() => {
            countdownBack()
        })
        return {
            countdownBackTime,
            errorData
        }
    }
})
